.questions {
  background-color: $White;
  padding-top: 152px;
  padding-bottom: 94px;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 40px;
  }

  &__title {
    max-width: 462px;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: $Orange;
  }

  &__descr {
    max-width: 340px;
    margin-bottom: 40px;
    font-weight: 300;
    line-height: 29px;
  }

  &__list {
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    list-style: none;
  }

  &__item {
    margin-top: 40px;
    margin-bottom: 40px;
    &__img {
      max-width: 300px;
      height: auto;
    }
    &__title {
      position: relative;
      padding-left: 26px;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      &::before {
        position: absolute;
        top: 13px;
        left: 0px;
        content: "";
        background: $SeaBlue;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        line-height: 2rem;
        text-align: center;
        vertical-align: middle;
        margin-right: 26px;
        background: $Orange;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .questions {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 780px) {
  .questions {
    &__wrapper {
      margin-bottom: 20px;
      grid-template-columns: 1fr;
    }

    &__title {
      max-width: 100%;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 20px;
    }

    &__descr {
      max-width: 100%;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 18px;
    }

    &__list {
      max-width: 100%;
      grid-template-columns: 1fr 1fr;
    }

    &__item {
      margin-top: 20px;
      margin-bottom: 20px;
      &__img {
        width: 100%;
      }
      &__title {
        padding-left: 20px;
        font-size: 14px;
        line-height: 19px;
        &::before {
          top: 7px;
        }
      }
    }
  }
}
