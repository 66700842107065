.quiz {
  padding-bottom: 80px;

  &__wrap {
    border: 2px solid #ec6519;
    background-color: $White;
  }

  &__title-wrap {
    display: flex;
    justify-content: space-around;
    padding: 39px;
    align-items: center;
  }

  &__title-step {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: $Orange;
  }

  &__title {
    padding-right: 100px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: $Orange;
  }

  &__form {
    max-width: 500px;
    margin: 0 auto;
    &__contact {
      margin: 0 auto;
    }
  }

  &__card {
    display: none;
    padding-bottom: 39px;
    padding-left: 39px;
    padding-right: 50px;
    background-color: $White;
    &__active {
      display: block;
    }
  }

  &__card-title {
    margin-bottom: 34px;
    font-weight: bold;
    line-height: 29px;
  }

  &__card-label {
    margin-bottom: 27px;
    padding-left: 10px;
    font-size: 18px;
    line-height: 22px;
  }

  &__card-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
  }

  &__btn {
    margin: 0 auto;
    margin-bottom: 39px;
  }
}
@media screen and (max-width: 780px) {
  .quiz {
    padding-bottom: 50px;

    &__title-wrap {
      display: flex;
      justify-content: space-around;
      padding: 10px;
      align-items: flex-start;
    }

    &__title-step {
      display: none;
    }

    &__title {
      margin-bottom: 15px;
      padding: 0;
      font-size: 18px;
      line-height: 20px;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__card-wrap {
      grid-template-columns: repeat(1, 1fr);
    }

    &__card-title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
    }

    &__card-label {
      font-size: 16px;
      line-height: 20px;
    }

    &__card {
      padding: 10px;

      .form__embedded-subscribe {
        margin-bottom: 10px;
      }
    }

    &__btn {
      max-width: 80%;
      margin-bottom: 39px;
    }

    &__form {
      &__contact {
        max-width: 100%;
      }
    }
  }
}
