.header {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $SeaBlue;
    padding: 10px 0;
    z-index: 100;

    &__active {
        display: block;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__logo {
        display: flex;
        align-items: center;
    }

    &__logo-link {
        width: 150px;
    }

    &__logo-text {
        margin-left: 20px;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 2%;
        color: $White;
        white-space: nowrap;
    }
}