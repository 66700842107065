.free-consultation {
    background: #96D5E7;
    background: linear-gradient(180deg, #ffffff 0%, #489BBA 100%);

    &__wrapper {
        background-image: url(../img/free-consultation.png);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;    
        padding-top: 87px;
        padding-bottom: 100px;
        padding-left: 163px;
    }

    &__form {
        margin-top: 59px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    &__title {
        max-width: 470px;
        margin-bottom: 46px;
        font-weight: bold;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $LightBlue;
    }
}