.payment {
  background: #96d5e7;
  &__wrapper {
    padding-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
  }
  &__wrap {
    display: flex;
    justify-content: space-around;
    overflow: hidden;
  }
  &__promo {
    max-width: 600px;
  }
  &__title {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase;
  }
  &__descr {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
  }
  &__item {
    max-width: 500px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;

    &::before {
      content: "";
      background: $Orange;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      text-align: center;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  &__link {
    font-size: inherit;
    line-height: 24px;
    color: $SeaBlue;
    transition: 200ms;
    transition-timing-function: ease-in-out;

    &:hover,
    &:focus,
    &:active {
      color: $Orange;
      transition: 200ms;
      transition-timing-function: ease-in-out;
    }
  }
  &__iframe-wrap {
    width: 600px;
  }
  &__iframe {
    width: 100%;
    height: 400px;
    background-color: #96d5e7;
  }
}
@media screen and (max-width: 1200px) {
  .payment {
    &__wrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
@media screen and (max-width: 780px) {
  .payment {
    &__wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
    &__title {
      font-size: 22px;
      line-height: 130%;
      margin-bottom: 10px;
    }
    &__descr {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 130%;
    }
    &__item {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 10px;
    }
    &__iframe-wrap {
      transform: scale(.55);
      transform-origin: 0 0;
    }
  }
}
