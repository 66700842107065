.failure {
  background-color: $SeaBlue;
  padding-top: 152px;
  padding-bottom: 160px;

  &__wrapper-title {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    margin-bottom: 74px;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }

  &__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: $White;
  }

  &__subtitle {
    max-width: 550px;
    font-weight: 300;
    line-height: 29px;
    color: $White;
  }

  &__card {
    display: none;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 129px;
    padding-bottom: 103px;
    flex-direction: column;
    justify-content: center;
    background-color: $LightBlue;
    transition: 200ms;
    transition-timing-function: ease-in-out;
  }
  &__card-hover {
    height: 550px;
    padding: 47px;
    display: none;
    flex-direction: column;
    justify-content: center;
    background-color: $Orange;
    transition: 200ms;
    transition-timing-function: ease-in-out;
  }

  &__card-hover-title {
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 29px;
    color: $White;
    transition: 200ms;
    transition-timing-function: ease-in-out;
  }

  &__card-hover-item {
    position: relative;
    padding-left: 49px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
    color: $SeaBlue;
    transition: 200ms;
    transition-timing-function: ease-in-out;
    &::before {
      position: absolute;
      left: 0;
      top: 12px;
      content: "";
      height: 1px;
      width: 24px;
      background-color: $White;
    }
  }

  &__card-active {
    display: flex;
  }

  &__card-title {
    margin-bottom: 48px;
    font-weight: bold;
    font-size: 58px;
    line-height: 70px;
    text-align: center;
    text-transform: uppercase;
    color: $Orange;
    transition: 200ms;
    transition-timing-function: ease-in-out;
  }

  &__card-btn {
    margin: 0 auto;
    width: 280px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    border: 2px solid $Orange;
    color: $White;
    transition: 200ms;
    transition-timing-function: ease-in-out;
    cursor: pointer;
    &__hover {
      margin-top: 13px;
      border: 2px solid $SeaBlue;
    }
  }
}

@media screen and (max-width: 1200px) {
  .failure {
    padding-top: 57px;
    padding-bottom: 50px;

    &__wrapper-title {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0;
      margin-bottom: 50px;
    }

    &__cards {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 25px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__card {
      padding-top: 50px;
      padding-bottom: 30px;
    }

    &__card-title {
      margin-bottom: 28px;
      font-size: 38px;
      line-height: 50px;
    }
  }
}

@media screen and (max-width: 780px) {
  .failure {
    padding-top: 57px;
    padding-bottom: 50px;

    &__wrapper-title {
      margin-bottom: 30px;
    }

    &__cards {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }

    &__title {
      margin-bottom: 20px;
      font-size: 22px;
    }

    &__subtitle {
      font-size: 20px;
    }

    &__card {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &__card-title {
      margin-bottom: 20px;
      font-size: 28px;
      line-height: 40px;
    }
  }
}
