.footer {
    background-color: $Grey;
    padding-top: 160px;
    padding-bottom: 135px;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: top;
    }

    &__logo {
        margin-bottom: 50px;
    }

    &__logo-link {
        width: 273px;
    }

    &__nav {
        margin-bottom: 68px;
    }

    &__nav-list {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    &__nav-item {
        max-width: 220px;
        margin-right: 76px;
    }

    &__nav-item-link {
        font-weight: bold;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: 0.01em;
        color: $SeaBlue;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            border-bottom: 2px solid $Orange;
        }
    }

    &__adress {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__adress-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        margin-right: 92px;
    }

    &__adress-item {
        font-weight: 300;
        font-size: 18px;
        line-height: 100%;
    }

    &__adress-list {
        display: grid;
        grid-template-columns: 245px 286px;
        column-gap: 92px;
        row-gap: 28px;
    }
}