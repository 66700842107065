.case {
  background-color: $LightBlue;
  padding-top: 152px;
  padding-bottom: 160px;

  &__wrapper-title {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    margin-bottom: 80px;
  }

  &__title {
    max-width: 413px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: $White;
  }

  &__subtitle {
    font-weight: 300;
    line-height: 29px;
    color: $White;
    margin-bottom: 74px;

    &__orange {
      color: $Orange;
    }
  }

  &__cards {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }

  &__card {
    position: relative;
    max-width: 550px;
    padding-top: 34px;
    padding-bottom: 49px;
    padding-left: 30px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $White;
  }

  &__card-title {
    margin-bottom: 68px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }

  &__card-wrapper {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &__last {
      margin-bottom: 72px;
    }
  }

  &__img-wrapper {
    max-width: 200px; 
    margin: 0 auto;
  }

  &__card-wrap {
    display: flex;
    justify-content: flex-start;
  }

  &__card-subtitle {
    margin-right: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: $DarkGrey;
  }

  &__card-text {
    font-weight: bold;
    font-size: 29px;
    line-height: 35px;

    &__name {
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__btn__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    cursor: pointer;

    circle {
      fill: $Orange;
      transition: 200ms;
      transition-timing-function: ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
      circle {
        fill: $SeaBlue;
        transition: 200ms;
        transition-timing-function: ease-in-out;
      }
    }
  }

  &__card-btn {
    align-items: flex-end;
    width: 280px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    border: 2px solid $SeaBlue;
    color: $SeaBlue;
    transition: 200ms;
    transition-timing-function: ease-in-out;

    &:hover,
    &:focus,
    &:active {
      background-color: $Orange;
      transition: 200ms;
      transition-timing-function: ease-in-out;
      border: 2px solid $Orange;
    }
  }
}
