/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* inter-300 - cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/inter-v2-cyrillic-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/inter-v2-cyrillic-300.eot?#iefix") format("embedded-opentype"), url("../fonts/inter-v2-cyrillic-300.woff2") format("woff2"), url("../fonts/inter-v2-cyrillic-300.woff") format("woff"), url("../fonts/inter-v2-cyrillic-300.ttf") format("truetype"), url("../fonts/inter-v2-cyrillic-300.svg#Inter") format("svg");
  /* Legacy iOS */ }

/* inter-regular - cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/inter-v2-cyrillic-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/inter-v2-cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/inter-v2-cyrillic-regular.woff2") format("woff2"), url("../fonts/inter-v2-cyrillic-regular.woff") format("woff"), url("../fonts/inter-v2-cyrillic-regular.ttf") format("truetype"), url("../fonts/inter-v2-cyrillic-regular.svg#Inter") format("svg");
  /* Legacy iOS */ }

/* inter-500 - cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/inter-v2-cyrillic-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/inter-v2-cyrillic-500.eot?#iefix") format("embedded-opentype"), url("../fonts/inter-v2-cyrillic-500.woff2") format("woff2"), url("../fonts/inter-v2-cyrillic-500.woff") format("woff"), url("../fonts/inter-v2-cyrillic-500.ttf") format("truetype"), url("../fonts/inter-v2-cyrillic-500.svg#Inter") format("svg");
  /* Legacy iOS */ }

/* inter-700 - cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/inter-v2-cyrillic-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/inter-v2-cyrillic-700.eot?#iefix") format("embedded-opentype"), url("../fonts/inter-v2-cyrillic-700.woff2") format("woff2"), url("../fonts/inter-v2-cyrillic-700.woff") format("woff"), url("../fonts/inter-v2-cyrillic-700.ttf") format("truetype"), url("../fonts/inter-v2-cyrillic-700.svg#Inter") format("svg");
  /* Legacy iOS */ }

/* inter-800 - cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/inter-v2-cyrillic-800.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/inter-v2-cyrillic-800.eot?#iefix") format("embedded-opentype"), url("../fonts/inter-v2-cyrillic-800.woff2") format("woff2"), url("../fonts/inter-v2-cyrillic-800.woff") format("woff"), url("../fonts/inter-v2-cyrillic-800.ttf") format("truetype"), url("../fonts/inter-v2-cyrillic-800.svg#Inter") format("svg");
  /* Legacy iOS */ }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: "Inter";
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  font-weight: normal;
  color: #1B1C2E; }

img {
  max-width: 100%;
  height: auto; }

.wrapper {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px; }

.promo-quiz__wrapper {
  background: linear-gradient(180deg, #96d5e7 0%, #489bba 100%); }

.nav__list {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; }

.nav__item {
  margin-right: 30px;
  align-self: center; }

.nav__item-link {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  text-decoration: none;
  transition: 200ms;
  transition-timing-function: ease-in-out; }
  .nav__item-link:hover, .nav__item-link:focus, .nav__item-link:active {
    color: #EC6519;
    transition: 200ms;
    transition-timing-function: ease-in-out; }

.messanger__list {
  width: 110px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center; }
  .messanger__list__promo {
    width: 150px; }

.burger {
  display: none;
  width: 30px;
  height: 22px;
  position: relative;
  cursor: pointer; }
  .burger__line {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 0; }
  .burger__line_first {
    top: 0; }
  .burger__line_second {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .burger__line_third {
    bottom: 0; }

.marker {
  position: absolute; }
  .marker__x {
    left: 0;
    bottom: 0;
    width: 24px;
    height: 24px; }
    .marker__x:before, .marker__x:after {
      position: absolute;
      left: 10px;
      content: "";
      height: 24px;
      width: 1px;
      background-color: #EC6519; }
    .marker__x:before {
      transform: rotate(45deg); }
    .marker__x:after {
      transform: rotate(-45deg); }
  .marker__plus {
    width: 24px;
    height: 24px; }
    .marker__plus:before, .marker__plus:after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      height: 24px;
      width: 1px;
      transition: 200ms;
      transition-timing-function: ease-in-out;
      background-color: #1B1C2E; }
    .marker__plus:before {
      transform: translateY(-50%) rotate(90deg); }
    .marker__plus__active:after {
      content: none; }

.form {
  display: flex;
  flex-direction: column; }
  .form__input {
    max-width: 469px;
    margin-bottom: 17px;
    padding-top: 13px;
    padding-bottom: 13px;
    border: none;
    outline: none;
    border-bottom: 1px solid #EAEAEA;
    background-color: inherit; }
    .form__input::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #999999;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
    .form__input__consult::placeholder {
      color: #FFFFFF;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
    .form__input:hover, .form__input:focus, .form__input:active {
      color: #1B1C2E;
      transition: 200ms;
      transition-timing-function: ease-in-out;
      border-bottom: 1px solid #1B1C2E; }
      .form__input:hover::placeholder, .form__input:focus::placeholder, .form__input:active::placeholder {
        transition: 400ms;
        transition-timing-function: ease-in-out;
        color: #1B1C2E; }
  .form__embedded-subscribe {
    margin-bottom: 41px;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #000000; }
    .form__embedded-subscribe__link {
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #999999;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
      .form__embedded-subscribe__link__consult {
        transition: 200ms;
        transition-timing-function: ease-in-out;
        color: #FFFFFF; }
        .form__embedded-subscribe__link__consult:hover, .form__embedded-subscribe__link__consult:focus, .form__embedded-subscribe__link__consult:active {
          color: #EC6519;
          transition: 200ms;
          transition-timing-function: ease-in-out; }
      .form__embedded-subscribe__link:hover, .form__embedded-subscribe__link:focus, .form__embedded-subscribe__link:active {
        color: #EC6519;
        transition: 200ms;
        transition-timing-function: ease-in-out; }
  .form input[type="checkbox"] {
    margin-right: 15px; }

.btn {
  display: flex;
  width: 280px;
  height: 60px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border: none;
  cursor: pointer;
  color: #FFFFFF; }
  .btn:disabled {
    opacity: 0.5; }
  .btn__orange {
    background-color: #EC6519;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
    .btn__orange:hover, .btn__orange:focus, .btn__orange:active {
      background-color: #1B1C2E;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
      .btn__orange:hover:disabled, .btn__orange:focus:disabled, .btn__orange:active:disabled {
        color: #FFFFFF;
        background-color: #EC6519;
        opacity: 0.5; }
  .btn__seablue {
    border: 2px solid #EC6519;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
    .btn__seablue:hover, .btn__seablue:focus {
      border-color: #1b1c2e;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
    .btn__seablue:active {
      background-color: #1b1c2e;
      transition: 200ms;
      transition-timing-function: ease-in-out; }

.header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #1B1C2E;
  padding: 10px 0;
  z-index: 100; }
  .header__active {
    display: block; }
  .header__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center; }
  .header__logo {
    display: flex;
    align-items: center; }
  .header__logo-link {
    width: 150px; }
  .header__logo-text {
    margin-left: 20px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2%;
    color: #FFFFFF;
    white-space: nowrap; }

.payment {
  background: #96d5e7; }
  .payment__wrapper {
    padding-top: 100px;
    padding-left: 40px;
    padding-right: 40px; }
  .payment__wrap {
    display: flex;
    justify-content: space-around;
    overflow: hidden; }
  .payment__promo {
    max-width: 600px; }
  .payment__title {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase; }
  .payment__descr {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px; }
  .payment__item {
    max-width: 500px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px; }
    .payment__item::before {
      content: "";
      background: #EC6519;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      text-align: center;
      vertical-align: middle;
      margin-right: 10px; }
  .payment__link {
    font-size: inherit;
    line-height: 24px;
    color: #1B1C2E;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
    .payment__link:hover, .payment__link:focus, .payment__link:active {
      color: #EC6519;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
  .payment__iframe-wrap {
    width: 600px; }
  .payment__iframe {
    width: 100%;
    height: 400px;
    background-color: #96d5e7; }

@media screen and (max-width: 1200px) {
  .payment__wrap {
    flex-direction: column;
    align-items: flex-start; } }

@media screen and (max-width: 780px) {
  .payment__wrapper {
    padding-left: 20px;
    padding-right: 20px; }
  .payment__title {
    font-size: 22px;
    line-height: 130%;
    margin-bottom: 10px; }
  .payment__descr {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 130%; }
  .payment__item {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 10px; }
  .payment__iframe-wrap {
    transform: scale(0.55);
    transform-origin: 0 0; } }

.promo {
  position: relative;
  overflow: hidden; }
  .promo__wrapper {
    background-image: url(../img/promo.png);
    background-size: 80% auto;
    background-position: bottom 0 right -100px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    padding-top: 67px;
    padding-bottom: 70px; }
  .promo__text__wrapper {
    display: inline-block;
    max-width: 280px;
    margin-left: 78px; }
  .promo__text__bold {
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    text-transform: uppercase; }
  .promo__text__small {
    line-height: 29px; }
  .promo__contacts-wrapper {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .promo__contacts-title {
    margin-bottom: 3px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 2%; }
  .promo__contacts-tel {
    min-width: 269px;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase;
    text-decoration: none;
    color: #1B1C2E;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
    .promo__contacts-tel:hover,
    .promo__contacts-tel :focus,
    .promo__contacts-tel :active {
      color: #EC6519;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
  .promo__contacts-email {
    font-weight: bold;
    font-size: 24px;
    text-decoration: none;
    color: #1B1C2E;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
    .promo__contacts-email:hover,
    .promo__contacts-email :focus,
    .promo__contacts-email :active {
      color: #EC6519;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
  .promo__logo {
    display: inline-block;
    margin-bottom: 67px; }
  .promo__title {
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 83px;
    line-height: 100px;
    letter-spacing: 1%;
    text-transform: uppercase; }
    .promo__title__size {
      margin-top: -6px;
      display: block;
      font-size: 61px;
      line-height: 74px;
      text-transform: uppercase; }
  .promo__descr {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 29px;
    line-height: 35px;
    color: #FFFFFF; }
  .promo__item {
    font-style: normal;
    font-weight: 500;
    line-height: 150%; }
    .promo__item::before {
      content: "";
      background: #1B1C2E;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      text-align: center;
      vertical-align: middle;
      margin-right: 10px; }
  .promo__btn-descr {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px; }
  .promo__stick {
    position: absolute;
    top: 307px;
    right: 0; }

@media screen and (max-width: 1400px) {
  .promo__text__wrapper {
    display: block;
    max-width: 500px;
    margin-left: 0; } }

@media screen and (max-width: 1200px) {
  .promo__wrapper {
    background: none;
    justify-content: center; }
  .promo__text {
    display: none; }
  .promo__stick {
    top: 50%; }
  .promo__contacts-wrapper {
    display: none; }
  .promo__logo {
    display: none; }
  .promo__btn {
    margin: 0 auto; } }

@media screen and (max-width: 780px) {
  .promo__wrapper {
    margin-top: 110px;
    padding-top: 20px;
    padding-bottom: 30px; }
  .promo__text__bold {
    font-size: 18px;
    line-height: 25px; }
  .promo__text__small {
    font-size: 13px;
    line-height: 19px; }
  .promo__title {
    margin-bottom: 15px;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 1%;
    text-transform: uppercase; }
    .promo__title__size {
      margin-top: -6px;
      display: block;
      font-size: 30px;
      line-height: 37px;
      text-transform: uppercase; }
  .promo__descr {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 19px;
    line-height: 17px;
    color: #FFFFFF; }
  .promo__list {
    margin-bottom: 20px; }
  .promo__item {
    font-size: 13px; }
  .promo__btn-descr {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 14px; } }

.questions {
  background-color: #FFFFFF;
  padding-top: 152px;
  padding-bottom: 94px; }
  .questions__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 40px; }
  .questions__title {
    max-width: 462px;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: #EC6519; }
  .questions__descr {
    max-width: 340px;
    margin-bottom: 40px;
    font-weight: 300;
    line-height: 29px; }
  .questions__list {
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    list-style: none; }
  .questions__item {
    margin-top: 40px;
    margin-bottom: 40px; }
    .questions__item__img {
      max-width: 300px;
      height: auto; }
    .questions__item__title {
      position: relative;
      padding-left: 26px;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px; }
      .questions__item__title::before {
        position: absolute;
        top: 13px;
        left: 0px;
        content: "";
        background: #1B1C2E;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        line-height: 2rem;
        text-align: center;
        vertical-align: middle;
        margin-right: 26px;
        background: #EC6519; }

@media screen and (max-width: 1200px) {
  .questions {
    padding-top: 50px;
    padding-bottom: 50px; } }

@media screen and (max-width: 780px) {
  .questions__wrapper {
    margin-bottom: 20px;
    grid-template-columns: 1fr; }
  .questions__title {
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 20px; }
  .questions__descr {
    max-width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 18px; }
  .questions__list {
    max-width: 100%;
    grid-template-columns: 1fr 1fr; }
  .questions__item {
    margin-top: 20px;
    margin-bottom: 20px; }
    .questions__item__img {
      width: 100%; }
    .questions__item__title {
      padding-left: 20px;
      font-size: 14px;
      line-height: 19px; }
      .questions__item__title::before {
        top: 7px; } }

.popular {
  background: linear-gradient(to right, #EAEAEA 50%, #EC6519 50%);
  padding-top: 120px;
  padding-bottom: 110px; }
  .popular__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr; }
  .popular__column-right {
    padding-left: 87px; }
    .popular__column-right .popular__item {
      margin-bottom: 40px;
      color: #FFFFFF; }
      .popular__column-right .popular__item__bold {
        font-weight: bold; }
    .popular__column-right .popular__title {
      margin-left: -20px; }
  .popular__title {
    max-width: 460px;
    margin-bottom: 61px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase; }
  .popular__item {
    position: relative;
    padding-left: 31px;
    margin-bottom: 29px;
    font-size: 24px;
    line-height: 29px; }
    .popular__item:last-child {
      margin-bottom: 0; }
  .popular__item-marker {
    position: absolute;
    top: 5px;
    left: -17px;
    width: 27px;
    height: 19px; }

@media screen and (max-width: 1200px) {
  .popular {
    padding-top: 50px;
    padding-bottom: 50px; }
    .popular__title {
      margin-bottom: 30px; } }

@media screen and (max-width: 780px) {
  .popular {
    background: linear-gradient(#EAEAEA 60%, #EC6519 40%); }
    .popular__wrapper {
      grid-template-columns: 1fr;
      grid-template-rows: 60% 40%; }
    .popular__title {
      font-size: 20px;
      line-height: 100%; }
    .popular__column-right {
      padding-top: 50px;
      padding-left: 0; }
      .popular__column-right .popular__item {
        margin-bottom: 20px; }
    .popular__item {
      font-size: 16px;
      line-height: 21px; } }

.consultation {
  background: linear-gradient(180deg, #96d5e7 0%, #489bba 100%); }
  .consultation__bg {
    background-image: url(../img/consultation.png);
    background-position: bottom -40px right -80px;
    background-repeat: no-repeat;
    background-size: contain; }
  .consultation__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 89px;
    padding-bottom: 160px;
    padding-right: 280px; }
  .consultation__title-wrapper {
    max-width: 540px; }
  .consultation__title {
    max-width: 473px;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    margin-bottom: 60px; }
    .consultation__title__bold {
      text-transform: uppercase;
      font-weight: bold; }
  .consultation__item {
    max-width: 452px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px; }
    .consultation__item::before {
      content: "";
      background: #EC6519;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      text-align: center;
      vertical-align: middle;
      margin-right: 10px; }

@media screen and (max-width: 1400px) {
  .consultation__bg {
    background-position: bottom -40px right -120px; } }

@media screen and (max-width: 1200px) {
  .consultation__bg {
    background-image: none; }
  .consultation__wrapper {
    justify-content: flex-start;
    padding-top: 57px;
    padding-bottom: 50px;
    padding-left: 40px; }
  .consultation__title {
    max-width: 700px;
    margin-bottom: 30px; }
  .consultation__form {
    margin-right: auto; } }

@media screen and (max-width: 780px) {
  .consultation__title {
    font-size: 22px;
    line-height: 130%; }
  .consultation__form {
    max-width: 100%;
    margin-right: auto; }
  .consultation__item {
    font-size: 13px;
    margin-bottom: 10px; } }

.failure {
  background-color: #1B1C2E;
  padding-top: 152px;
  padding-bottom: 160px; }
  .failure__wrapper-title {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    margin-bottom: 74px; }
  .failure__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px; }
  .failure__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: #FFFFFF; }
  .failure__subtitle {
    max-width: 550px;
    font-weight: 300;
    line-height: 29px;
    color: #FFFFFF; }
  .failure__card {
    display: none;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 129px;
    padding-bottom: 103px;
    flex-direction: column;
    justify-content: center;
    background-color: #32374E;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
  .failure__card-hover {
    height: 550px;
    padding: 47px;
    display: none;
    flex-direction: column;
    justify-content: center;
    background-color: #EC6519;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
  .failure__card-hover-title {
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 29px;
    color: #FFFFFF;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
  .failure__card-hover-item {
    position: relative;
    padding-left: 49px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
    color: #1B1C2E;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
    .failure__card-hover-item::before {
      position: absolute;
      left: 0;
      top: 12px;
      content: "";
      height: 1px;
      width: 24px;
      background-color: #FFFFFF; }
  .failure__card-active {
    display: flex; }
  .failure__card-title {
    margin-bottom: 48px;
    font-weight: bold;
    font-size: 58px;
    line-height: 70px;
    text-align: center;
    text-transform: uppercase;
    color: #EC6519;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
  .failure__card-btn {
    margin: 0 auto;
    width: 280px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    border: 2px solid #EC6519;
    color: #FFFFFF;
    transition: 200ms;
    transition-timing-function: ease-in-out;
    cursor: pointer; }
    .failure__card-btn__hover {
      margin-top: 13px;
      border: 2px solid #1B1C2E; }

@media screen and (max-width: 1200px) {
  .failure {
    padding-top: 57px;
    padding-bottom: 50px; }
    .failure__wrapper-title {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0;
      margin-bottom: 50px; }
    .failure__cards {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 25px; }
    .failure__title {
      margin-bottom: 30px; }
    .failure__card {
      padding-top: 50px;
      padding-bottom: 30px; }
    .failure__card-title {
      margin-bottom: 28px;
      font-size: 38px;
      line-height: 50px; } }

@media screen and (max-width: 780px) {
  .failure {
    padding-top: 57px;
    padding-bottom: 50px; }
    .failure__wrapper-title {
      margin-bottom: 30px; }
    .failure__cards {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px; }
    .failure__title {
      margin-bottom: 20px;
      font-size: 22px; }
    .failure__subtitle {
      font-size: 20px; }
    .failure__card {
      padding-top: 20px;
      padding-bottom: 20px; }
    .failure__card-title {
      margin-bottom: 20px;
      font-size: 28px;
      line-height: 40px; } }

.case {
  background-color: #32374E;
  padding-top: 152px;
  padding-bottom: 160px; }
  .case__wrapper-title {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    margin-bottom: 80px; }
  .case__title {
    max-width: 413px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: #FFFFFF; }
  .case__subtitle {
    font-weight: 300;
    line-height: 29px;
    color: #FFFFFF;
    margin-bottom: 74px; }
    .case__subtitle__orange {
      color: #EC6519; }
  .case__cards {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px; }
  .case__card {
    position: relative;
    max-width: 550px;
    padding-top: 34px;
    padding-bottom: 49px;
    padding-left: 30px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #FFFFFF; }
  .case__card-title {
    margin-bottom: 68px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-transform: uppercase; }
  .case__card-wrapper {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .case__card-wrapper__last {
      margin-bottom: 72px; }
  .case__img-wrapper {
    max-width: 200px;
    margin: 0 auto; }
  .case__card-wrap {
    display: flex;
    justify-content: flex-start; }
  .case__card-subtitle {
    margin-right: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #999999; }
  .case__card-text {
    font-weight: bold;
    font-size: 29px;
    line-height: 35px; }
    .case__card-text__name {
      font-weight: 300;
      font-size: 24px;
      line-height: 29px; }
  .case__btn__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    cursor: pointer; }
    .case__btn__next circle {
      fill: #EC6519;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
    .case__btn__next:hover circle, .case__btn__next:focus circle, .case__btn__next:active circle {
      fill: #1B1C2E;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
  .case__card-btn {
    align-items: flex-end;
    width: 280px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    border: 2px solid #1B1C2E;
    color: #1B1C2E;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
    .case__card-btn:hover, .case__card-btn:focus, .case__card-btn:active {
      background-color: #EC6519;
      transition: 200ms;
      transition-timing-function: ease-in-out;
      border: 2px solid #EC6519; }

.works {
  background-color: #FFFFFF;
  background-image: url(/img/works.png);
  background-size: 875px cover;
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  padding-top: 140px;
  padding-bottom: 140px; }
  .works__title-wrapper {
    margin-top: 255px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px; }
  .works__title {
    max-width: 550px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-transform: uppercase; }
    .works__title__numb {
      font-size: 64px; }
    .works__title__orange {
      color: #EC6519; }
  .works__subtitle {
    width: 161px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #1B1C2E; }
  .works__subtitle-wrapper {
    width: 580px;
    height: 118px;
    margin-left: -52px;
    border: 2px solid #ec6519;
    box-sizing: border-box;
    border-radius: 60px;
    padding-left: 54px;
    display: flex;
    align-items: center; }
  .works__play {
    width: 170px;
    height: 170px;
    vertical-align: middle; }
    .works__play__link {
      margin-left: 80px; }
      .works__play__link:focus,
      .works__play__link :hover {
        fill: #EC6519; }
  .works__text-wrapper {
    max-width: 607px;
    margin-bottom: 113px;
    border: 2px solid #EC6519;
    padding-top: 30px;
    padding-left: 41px;
    padding-bottom: 28px; }
  .works__text-title {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px; }
  .works__text-item {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px; }
    .works__text-item__nobold {
      margin-left: 35px;
      font-weight: normal; }
    .works__text-item::before {
      content: "";
      background: #1B1C2E;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      text-align: center;
      vertical-align: middle;
      margin-right: 22px;
      background: #EC6519; }
  .works__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    margin-bottom: 63px; }
  .works__item {
    position: relative;
    max-width: 550px;
    padding-top: 38px;
    padding-right: 30px;
    transition: 200ms;
    transition-timing-function: ease-in-out;
    border-bottom: 1px solid #EAEAEA;
    cursor: pointer; }
    .works__item:hover, .works__item:focus, .works__item:active {
      border-bottom: 1px solid #EC6519;
      transition: 200ms;
      transition-timing-function: ease-in-out; }
      .works__item:hover .marker__plus:before, .works__item:hover .marker__plus:after, .works__item:focus .marker__plus:before, .works__item:focus .marker__plus:after, .works__item:active .marker__plus:before, .works__item:active .marker__plus:after {
        transition: 200ms;
        transition-timing-function: ease-in-out;
        background-color: #EC6519; }
  .works__item-title {
    margin-bottom: 26px;
    font-weight: 300;
    line-height: 29px; }
  .works__item-text {
    display: none; }
    .works__item-text__active {
      display: block;
      font-size: 18px;
      line-height: 22px;
      color: #999999;
      margin-bottom: 30px; }
  .works__label {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px; }
  .works__label-item {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
  .works__label-text {
    max-width: 400px;
    margin-left: 31px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #EC6519; }

@media screen and (max-width: 1200px) {
  .works {
    padding-top: 57px;
    padding-bottom: 0px; }
    .works__text-wrapper {
      margin-bottom: 53px; }
    .works__subtitle {
      width: 180px; }
    .works__subtitle-wrapper {
      margin-bottom: 25px;
      width: 380px;
      margin-left: 0;
      padding-left: 40px; }
    .works__play__link {
      margin-left: 40px; }
    .works__list {
      grid-template-columns: 1fr; }
    .works__item {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 15px;
      font-size: 20px;
      line-height: 26px; }
    .works__label {
      display: none; } }

@media screen and (max-width: 780px) {
  .works {
    background-size: 100%; }
    .works__title {
      font-size: 22px;
      line-height: 130%; }
    .works__subtitle {
      width: 200px;
      font-size: 14px;
      line-height: 18px; }
    .works__subtitle-wrapper {
      margin-bottom: 20px;
      width: 100%;
      height: 80px;
      margin-left: 0;
      border-radius: 60px;
      padding-left: 30px; }
    .works__play {
      width: 70px;
      height: 70px;
      vertical-align: middle; }
      .works__play__link {
        margin-left: 0;
        margin-right: 5px; }
    .works__title-wrapper {
      margin-top: 50px;
      display: block; }
    .works__text-wrapper {
      max-width: 607px;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px; }
    .works__title {
      max-width: 100%;
      font-size: 18px;
      line-height: 100%; }
      .works__title__numb {
        font-size: 24px; }
      .works__title__orange {
        color: #EC6519; }
    .works__text-title {
      font-size: 18px;
      line-height: 22px; }
    .works__text-item {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px; } }

.material {
  background-color: #FFFFFF;
  background-image: url(../img/file.png);
  background-position: top 240px left 200px;
  background-repeat: no-repeat;
  background-size: 30%; }
  .material__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 152px;
    padding-bottom: 80px; }
  .material__wrapper-text {
    max-width: 470px; }
  .material__title {
    margin-left: 188px;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase;
    font-weight: bold; }
  .material__text {
    margin-bottom: 74px;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: #32374E; }
    .material__text__bold {
      font-weight: bold; }
  .material__btn {
    margin-bottom: 40px; }

@media screen and (max-width: 1200px) {
  .material {
    background-position: top 240px left 50px;
    background-repeat: no-repeat;
    background-size: 40%; }
    .material__wrapper {
      flex-direction: column;
      align-items: flex-end;
      justify-content: start; }
    .material__title {
      margin-left: 0;
      margin-bottom: 10px; }
    .material__btn {
      margin: 0 auto;
      margin-bottom: 15px; } }

@media screen and (max-width: 780px) {
  .material {
    background-image: none; }
    .material__wrapper {
      padding-top: 57px;
      padding-bottom: 50px;
      flex-direction: column;
      align-items: center; }
    .material__title {
      margin: 0 auto;
      text-align: center;
      font-size: 22px;
      line-height: 130%;
      margin-bottom: 15px; }
    .material__wrapper-text {
      margin-bottom: 0; }
    .material__text {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px; }
    .material__btn {
      margin: 0 auto;
      margin-bottom: 15px; } }

.quiz {
  padding-bottom: 80px; }
  .quiz__wrap {
    border: 2px solid #ec6519;
    background-color: #FFFFFF; }
  .quiz__title-wrap {
    display: flex;
    justify-content: space-around;
    padding: 39px;
    align-items: center; }
  .quiz__title-step {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: #EC6519; }
  .quiz__title {
    padding-right: 100px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 1%;
    text-transform: uppercase;
    color: #EC6519; }
  .quiz__form {
    max-width: 500px;
    margin: 0 auto; }
    .quiz__form__contact {
      margin: 0 auto; }
  .quiz__card {
    display: none;
    padding-bottom: 39px;
    padding-left: 39px;
    padding-right: 50px;
    background-color: #FFFFFF; }
    .quiz__card__active {
      display: block; }
  .quiz__card-title {
    margin-bottom: 34px;
    font-weight: bold;
    line-height: 29px; }
  .quiz__card-label {
    margin-bottom: 27px;
    padding-left: 10px;
    font-size: 18px;
    line-height: 22px; }
  .quiz__card-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px; }
  .quiz__btn {
    margin: 0 auto;
    margin-bottom: 39px; }

@media screen and (max-width: 780px) {
  .quiz {
    padding-bottom: 50px; }
    .quiz__title-wrap {
      display: flex;
      justify-content: space-around;
      padding: 10px;
      align-items: flex-start; }
    .quiz__title-step {
      display: none; }
    .quiz__title {
      margin-bottom: 15px;
      padding: 0;
      font-size: 18px;
      line-height: 20px; }
    .quiz__wrapper {
      display: flex;
      flex-direction: column; }
    .quiz__card-wrap {
      grid-template-columns: repeat(1, 1fr); }
    .quiz__card-title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px; }
    .quiz__card-label {
      font-size: 16px;
      line-height: 20px; }
    .quiz__card {
      padding: 10px; }
      .quiz__card .form__embedded-subscribe {
        margin-bottom: 10px; }
    .quiz__btn {
      max-width: 80%;
      margin-bottom: 39px; }
    .quiz__form__contact {
      max-width: 100%; } }

.free-consultation {
  background: #96D5E7;
  background: linear-gradient(180deg, #ffffff 0%, #489BBA 100%); }
  .free-consultation__wrapper {
    background-image: url(../img/free-consultation.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 87px;
    padding-bottom: 100px;
    padding-left: 163px; }
  .free-consultation__form {
    margin-top: 59px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start; }
  .free-consultation__title {
    max-width: 470px;
    margin-bottom: 46px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #32374E; }

.footer {
  background-color: #EAEAEA;
  padding-top: 160px;
  padding-bottom: 135px; }
  .footer__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: top; }
  .footer__logo {
    margin-bottom: 50px; }
  .footer__logo-link {
    width: 273px; }
  .footer__nav {
    margin-bottom: 68px; }
  .footer__nav-list {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap; }
  .footer__nav-item {
    max-width: 220px;
    margin-right: 76px; }
  .footer__nav-item-link {
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #1B1C2E;
    text-decoration: none; }
    .footer__nav-item-link:hover, .footer__nav-item-link:focus, .footer__nav-item-link:active {
      border-bottom: 2px solid #EC6519; }
  .footer__adress {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .footer__adress-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-right: 92px; }
  .footer__adress-item {
    font-weight: 300;
    font-size: 18px;
    line-height: 100%; }
  .footer__adress-list {
    display: grid;
    grid-template-columns: 245px 286px;
    column-gap: 92px;
    row-gap: 28px; }

.popup {
  z-index: 1000;
  position: fixed;
  overflow-y: scroll;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #EAEAEA; }
  .popup__wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) !important;
    top: 5%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    background: #FFFFFF; }
  .popup__close {
    position: absolute;
    top: 13px;
    right: 13px;
    width: 27px;
    height: 21px;
    background: transparent;
    color: #999999;
    border: none;
    outline: none; }
  .popup__title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 28px;
    line-height: 130%;
    font-weight: 700; }
  .popup__text {
    text-align: center;
    font-size: 22px;
    line-height: 100%;
    margin-bottom: 15px; }
  .popup__embedded-subscribe {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px; }
  .popup__btn {
    margin: 0 auto; }

.stick__title {
  font-weight: bold;
  font-size: 29px;
  line-height: 35px;
  text-transform: uppercase;
  color: #EC6519; }

.stick__descr {
  line-height: 29px;
  color: #FFFFFF; }

.stick__item {
  width: 350px;
  height: 84px;
  background: #32374E;
  border-radius: 60px 0 0 60px;
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom: 13px; }

@media screen and (max-width: 1200px) {
  .stick__item {
    transition: 0.2s ease-in-out;
    transform: translateX(316px);
    cursor: pointer; }
    .stick__item:hover {
      transform: none;
      transition: 0.2s ease-in-out; } }

@media screen and (max-width: 780px) {
  .stick__wrapper {
    display: none; } }

.answer {
  padding-top: 140px;
  padding-bottom: 140px; }
  .answer__wrapper {
    display: flex;
    justify-content: space-between; }
  .answer__title {
    max-width: 462px;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 1%;
    text-transform: uppercase; }
  .answer__block {
    padding-top: 31px;
    padding-bottom: 40px;
    padding-left: 38px;
    padding-right: 40px;
    max-width: 680px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #EC6519;
    line-height: 29px; }
  .answer__text {
    max-width: 450px;
    margin-right: 62px; }
    .answer__text__bold {
      font-weight: bold; }
  .answer__btn-arrow circle {
    fill: #EC6519;
    transition: 200ms;
    transition-timing-function: ease-in-out; }
  .answer__btn-arrow:hover circle, .answer__btn-arrow:focus circle, .answer__btn-arrow:active circle {
    fill: #1B1C2E;
    transition: 200ms;
    transition-timing-function: ease-in-out; }

@media screen and (max-width: 1200px) {
  .answer__text {
    margin-right: 10px; } }

@media screen and (max-width: 780px) {
  .answer {
    padding-top: 50px;
    padding-bottom: 50px; }
    .answer__wrapper {
      flex-direction: column; }
    .answer__title {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 28px; }
    .answer__block {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
      max-width: 100%;
      line-height: 29px; }
    .answer__text {
      max-width: 100%;
      margin-right: 10px;
      font-size: 12px;
      line-height: 14px; }
      .answer__text__bold {
        font-size: 14px;
        line-height: 16px; } }

.contacts__wrapper {
  padding-top: 140px;
  padding-bottom: 140px; }

.contacts__title {
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.01em;
  text-transform: uppercase; }

.contacts__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px; }

.contacts__item {
  display: flex;
  flex-direction: column;
  margin-top: 80px; }
  .contacts__item__title {
    align-self: flex-start;
    margin-top: 40px;
    margin-bottom: 13px;
    font-weight: bold;
    line-height: 29px; }
  .contacts__item__descr {
    align-self: flex-start;
    font-weight: 300;
    line-height: 29px;
    margin-bottom: 13px; }
    .contacts__item__descr__bold {
      font-weight: bold; }

@media screen and (max-width: 780px) {
  .contacts__wrapper {
    padding-top: 50px;
    padding-bottom: 50px; }
  .contacts__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-transform: uppercase; }
  .contacts__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
  .contacts__item {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    flex-wrap: wrap; }
    .contacts__item img {
      max-width: 150px; }
    .contacts__item__title {
      align-self: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: bold;
      line-height: 22px;
      font-size: 18px; }
    .contacts__item__descr {
      align-self: flex-start;
      font-weight: 300;
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 10px; }
      .contacts__item__descr__bold {
        font-weight: bold; } }

@media screen and (max-width: 1200px) {
  .wrapper {
    padding-left: 40px;
    padding-right: 40px; }
  .header__logo-text {
    display: none; }
  .messanger {
    display: none; } }

@media screen and (max-width: 780px) {
  .header__burger {
    display: block; }
  .nav {
    position: absolute;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 40px;
    background-color: #1B1C2E;
    z-index: 200;
    transform: translateX(100%);
    transition: 0.2s all linear; }
    .nav__item {
      width: 100%;
      margin-right: 0;
      margin-left: 30px;
      margin-bottom: 30px; }
    .nav__active {
      transform: translateX(0); }
    .nav__close {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 200;
      cursor: pointer; }
    .nav__close-line {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 50%; }
    .nav__close-line:first-child {
      transform: translateY(-50%) rotate(45deg); }
    .nav__close-line:last-child {
      transform: translateY(-50%) rotate(-45deg); }
  .case {
    padding-top: 57px;
    padding-bottom: 50px; }
    .case__wrapper-title {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 20px; }
    .case__title {
      text-align: center;
      font-size: 22px;
      line-height: 130%;
      min-width: 100%;
      margin-bottom: 15px; }
    .case__subtitle {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px; }
    .case__btn {
      margin: 0 auto; }
    .case__cards {
      grid-template-columns: repeat(1, 1fr); }
    .case__card {
      padding: 15px; }
    .case__card-wrap {
      flex-direction: column; }
    .case__img-wrapper {
      display: none; }
    .case__card-btn {
      width: 100%; }
  .free-consultation__wrapper {
    background-image: none;
    padding-top: 57px;
    padding-bottom: 50px; }
  .free-consultation__title {
    font-size: 22px;
    line-height: 130%; }
  .form__input__consult {
    max-width: 100%; }
  .form__embedded-subscribe {
    margin-top: 15px;
    margin-bottom: 15px; }
  .form__btn {
    margin: 0 auto; }
  .footer {
    padding-top: 57px;
    padding-bottom: 50px; }
    .footer__wrapper {
      flex-direction: column; }
    .footer__left-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .footer__messanger {
      margin: 0 auto; }
    .footer__logo {
      margin-bottom: 15px; }
    .footer__nav {
      margin-bottom: 15px; }
    .footer__nav-list {
      text-align: center;
      flex-direction: column;
      justify-content: start;
      align-items: center; }
    .footer__nav-item {
      margin-right: 0; }
    .footer__adress {
      flex-direction: column; }
    .footer__adress-title {
      font-size: 16px;
      line-height: 20px; }
    .footer__adress-list {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px; }
    .footer__adress-item {
      font-size: 16px;
      line-height: 20px; } }
