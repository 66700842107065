.material {
  background-color: $White;
  background-image: url(../img/file.png);
  background-position: top 240px left 200px;
  background-repeat: no-repeat;
  background-size: 30%;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 152px;
    padding-bottom: 80px;
  }

  &__wrapper-text {
    max-width: 470px;
  }

  &__title {
    margin-left: 188px;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__text {
    margin-bottom: 74px;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: $LightBlue;

    &__bold {
      font-weight: bold;
    }
  }

  &__btn {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .material {
    background-position: top 240px left 50px;
    background-repeat: no-repeat;
    background-size: 40%;

    &__wrapper {
      flex-direction: column;
      align-items: flex-end;
      justify-content: start;
    }

    &__title {
      margin-left: 0;
      margin-bottom: 10px;
    }
    &__btn {
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 780px) {
  .material {
    background-image: none;

    &__wrapper {
      padding-top: 57px;
      padding-bottom: 50px;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      margin: 0 auto;
      text-align: center;
      font-size: 22px;
      line-height: 130%;
      margin-bottom: 15px;
    }

    &__wrapper-text {
      margin-bottom: 0;
    }

    &__text {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px;
    }

    &__btn {
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }
}
