.answer {
  padding-top: 140px;
  padding-bottom: 140px;

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    max-width: 462px;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 1%;
    text-transform: uppercase;
  }

  &__block {
    padding-top: 31px;
    padding-bottom: 40px;
    padding-left: 38px;
    padding-right: 40px;
    max-width: 680px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid $Orange;
    line-height: 29px;
  }

  &__text {
    max-width: 450px;
    margin-right: 62px;

    &__bold {
      font-weight: bold;
    }
  }

  &__btn-arrow {
    circle {
      fill: $Orange;
      transition: 200ms;
      transition-timing-function: ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
      circle {
        fill: $SeaBlue;
        transition: 200ms;
        transition-timing-function: ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .answer {
    &__text {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 780px) {
  .answer {
    padding-top: 50px;
    padding-bottom: 50px;

    &__wrapper {
      flex-direction: column;
    }

    &__title {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 28px;
    }

    &__block {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
      max-width: 100%;
      line-height: 29px;
    }

    &__text {
      max-width: 100%;
      margin-right: 10px;
      font-size: 12px;
      line-height: 14px;
      &__bold {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
