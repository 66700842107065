.promo {
  position: relative;
  overflow: hidden;

  &__wrapper {
    background-image: url(../img/promo.png);
    background-size: 80% auto;
    background-position: bottom 0 right -100px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    padding-top: 67px;
    padding-bottom: 70px;
  }

  &__text {
    &__wrapper {
      display: inline-block;
      max-width: 280px;
      margin-left: 78px;
    }
    &__bold {
      font-weight: bold;
      font-size: 25px;
      line-height: 35px;
      text-transform: uppercase;
    }
    &__small {
      line-height: 29px;
    }
  }

  &__contacts-wrapper {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__contacts-title {
    margin-bottom: 3px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 2%;
  }

  &__contacts-tel {
    min-width: 269px;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase;
    text-decoration: none;
    color: $SeaBlue;
    transition: 200ms;
    transition-timing-function: ease-in-out;

    &:hover,
    :focus,
    :active {
      color: $Orange;
      transition: 200ms;
      transition-timing-function: ease-in-out;
    }
  }

  &__contacts-email {
    font-weight: bold;
    font-size: 24px;
    text-decoration: none;
    color: $SeaBlue;
    transition: 200ms;
    transition-timing-function: ease-in-out;

    &:hover,
    :focus,
    :active {
      color: $Orange;
      transition: 200ms;
      transition-timing-function: ease-in-out;
    }
  }

  &__logo {
    display: inline-block;
    margin-bottom: 67px;
  }

  &__title {
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 83px;
    line-height: 100px;
    letter-spacing: 1%;
    text-transform: uppercase;

    &__size {
      margin-top: -6px;
      display: block;
      font-size: 61px;
      line-height: 74px;
      text-transform: uppercase;
    }
  }

  &__descr {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 29px;
    line-height: 35px;
    color: $White;
  }

  &__item {
    font-style: normal;
    font-weight: 500;
    line-height: 150%;

    &::before {
      content: "";
      background: $SeaBlue;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      text-align: center;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  &__btn-descr {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
  }

  &__stick {
    position: absolute;
    top: 307px;
    right: 0;
  }
}

@media screen and (max-width: 1400px) {
  .promo {
    &__text {
      &__wrapper {
        display: block;
        max-width: 500px;
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .promo {
    &__wrapper {
      background: none;
      justify-content: center;
    }

    &__text {
      display: none;
    }

    &__stick {
      top: 50%;
    }

    &__contacts-wrapper {
      display: none;
    }

    &__logo {
      display: none;
    }

    &__btn {
      margin: 0 auto;
    }
  }
}

@media screen and  (max-width: 780px) {
  .promo {
    &__wrapper {
      margin-top: 110px;
      padding-top: 20px;
      padding-bottom: 30px;
    }

    &__text {
      &__bold {
        font-size: 18px;
        line-height: 25px;
      }
      &__small {
        font-size: 13px;
        line-height: 19px;
      }
    }

    &__title {
      margin-bottom: 15px;
      font-weight: 800;
      font-size: 40px;
      line-height: 50px;
      letter-spacing: 1%;
      text-transform: uppercase;

      &__size {
        margin-top: -6px;
        display: block;
        font-size: 30px;
        line-height: 37px;
        text-transform: uppercase;
      }
    }

    &__descr {
      margin-bottom: 15px;
      font-weight: bold;
      font-size: 19px;
      line-height: 17px;
      color: $White;
    }

    &__list {
      margin-bottom: 20px;
    }

    &__item {
      font-size: 13px;
    }

    &__btn-descr {
      text-align: center;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
