/* inter-300 - cyrillic */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/inter-v2-cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/inter-v2-cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/inter-v2-cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/inter-v2-cyrillic-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/inter-v2-cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/inter-v2-cyrillic-300.svg#Inter') format('svg'); /* Legacy iOS */
  }
  /* inter-regular - cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/inter-v2-cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/inter-v2-cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/inter-v2-cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/inter-v2-cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/inter-v2-cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/inter-v2-cyrillic-regular.svg#Inter') format('svg'); /* Legacy iOS */
  }
  /* inter-500 - cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/inter-v2-cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/inter-v2-cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/inter-v2-cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/inter-v2-cyrillic-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/inter-v2-cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/inter-v2-cyrillic-500.svg#Inter') format('svg'); /* Legacy iOS */
  }
  /* inter-700 - cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/inter-v2-cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/inter-v2-cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/inter-v2-cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/inter-v2-cyrillic-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/inter-v2-cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/inter-v2-cyrillic-700.svg#Inter') format('svg'); /* Legacy iOS */
  }
  /* inter-800 - cyrillic */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/inter-v2-cyrillic-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/inter-v2-cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/inter-v2-cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/inter-v2-cyrillic-800.woff') format('woff'), /* Modern Browsers */
         url('../fonts/inter-v2-cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/inter-v2-cyrillic-800.svg#Inter') format('svg'); /* Legacy iOS */
  }