@media screen and (max-width: 1200px) {
  .wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }

  .header {
    &__logo-text {
      display: none;
    }
  }

  .messanger {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  .header {
    &__burger {
      display: block;
    }
  }

  .nav {
    position: absolute;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 40px;
    background-color: $SeaBlue;
    z-index: 200;
    transform: translateX(100%);
    transition: 0.2s all linear;

    &__item {
      width: 100%;
      margin-right: 0;
      margin-left: 30px;
      margin-bottom: 30px;
    }

    &__active {
      transform: translateX(0);
    }

    &__close {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 200;
      cursor: pointer;
    }

    &__close-line {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 50%;
    }

    &__close-line:first-child {
      transform: translateY(-50%) rotate(45deg);
    }

    &__close-line:last-child {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  .case {
    padding-top: 57px;
    padding-bottom: 50px;

    &__wrapper-title {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 20px;
    }

    &__title {
      text-align: center;
      font-size: 22px;
      line-height: 130%;
      min-width: 100%;
      margin-bottom: 15px;
    }

    &__subtitle {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px;
    }

    &__btn {
      margin: 0 auto;
    }

    &__cards {
      grid-template-columns: repeat(1, 1fr);
    }

    &__card {
      padding: 15px;
    }

    &__card-wrap {
      flex-direction: column;
    }

    &__img-wrapper {
      display: none;
    }

    &__card-btn {
      width: 100%;
    }
  }

  .free-consultation {
    &__wrapper {
      background-image: none;
      padding-top: 57px;
      padding-bottom: 50px;
    }

    &__title {
      font-size: 22px;
      line-height: 130%;
    }
  }

  .form {
    &__input__consult {
      max-width: 100%;
    }

    &__embedded-subscribe {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &__btn {
      margin: 0 auto;
    }
  }

  .footer {
    padding-top: 57px;
    padding-bottom: 50px;

    &__wrapper {
      flex-direction: column;
    }

    &__left-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__messanger {
      margin: 0 auto;
    }

    &__logo {
      margin-bottom: 15px;
    }

    &__nav {
      margin-bottom: 15px;
    }

    &__nav-list {
      text-align: center;
      flex-direction: column;
      justify-content: start;
      align-items: center;
    }

    &__nav-item {
      margin-right: 0;
    }

    &__adress {
      flex-direction: column;
    }

    &__adress-title {
      font-size: 16px;
      line-height: 20px;
    }

    &__adress-list {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }

    &__adress-item {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
