.stick {
  &__title {
    font-weight: bold;
    font-size: 29px;
    line-height: 35px;
    text-transform: uppercase;
    color: $Orange;
  }
  &__descr {
    line-height: 29px;
    color: $White;
  }
  &__item {
    width: 350px;
    height: 84px;
    background: $LightBlue;
    border-radius: 60px 0 0 60px;
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 10px;
    margin-bottom: 13px;
  }
}

@media screen and (max-width: 1200px) {
  .stick {
    &__item {
      transition: 0.2s ease-in-out;
      transform: translateX(316px);
      cursor: pointer;
      &:hover {
        transform: none;
        transition: 0.2s ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .stick {
    &__wrapper {
      display: none;
    }
  }
}
