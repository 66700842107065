html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "Inter";
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  font-weight: normal;
  color: $SeaBlue;
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}

.promo-quiz__wrapper {
  background: linear-gradient(180deg, #96d5e7 0%, #489bba 100%);
}

.nav {
  &__list {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__item {
    margin-right: 30px;
    align-self: center;
  }

  &__item-link {
    font-size: 16px;
    line-height: 19px;
    color: $White;
    text-decoration: none;
    transition: 200ms;
    transition-timing-function: ease-in-out;

    &:hover,
    &:focus,
    &:active {
      color: $Orange;
      transition: 200ms;
      transition-timing-function: ease-in-out;
    }
  }
}

.messanger {
  &__list {
    width: 110px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    &__promo {
      width: 150px;
    }
  }
}

.burger {
  display: none;
  width: 30px;
  height: 22px;
  position: relative;
  cursor: pointer;
  &__line {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 0;
  }

  &__line_first {
    top: 0;
  }

  &__line_second {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &__line_third {
    bottom: 0;
  }
}

.marker {
  position: absolute;

  &__x {
    left: 0;
    bottom: 0;
    width: 24px;
    height: 24px;

    &:before,
    &:after {
      position: absolute;
      left: 10px;
      content: "";
      height: 24px;
      width: 1px;
      background-color: $Orange;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__plus {
    width: 24px;
    height: 24px;

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      height: 24px;
      width: 1px;
      transition: 200ms;
      transition-timing-function: ease-in-out;
      background-color: $SeaBlue;
    }

    &:before {
      transform: translateY(-50%) rotate(90deg);
    }
    &__active {
      &:after {
        content: none;
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;

  &__input {
    max-width: 469px;
    margin-bottom: 17px;
    padding-top: 13px;
    padding-bottom: 13px;
    border: none;
    outline: none;
    border-bottom: 1px solid $Grey;
    background-color: inherit;

    &::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: $DarkGrey;
      transition: 200ms;
      transition-timing-function: ease-in-out;
    }

    &__consult {
      &::placeholder {
        color: $White;
        transition: 200ms;
        transition-timing-function: ease-in-out;
      }
    }

    &:hover,
    &:focus,
    &:active {
      color: $SeaBlue;
      transition: 200ms;
      transition-timing-function: ease-in-out;
      border-bottom: 1px solid $SeaBlue;

      &::placeholder {
        transition: 400ms;
        transition-timing-function: ease-in-out;
        color: $SeaBlue;
      }
    }
  }

  &__embedded-subscribe {
    margin-bottom: 41px;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: $Black;

    &__link {
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: $DarkGrey;
      transition: 200ms;
      transition-timing-function: ease-in-out;

      &__consult {
        transition: 200ms;
        transition-timing-function: ease-in-out;
        color: $White;

        &:hover,
        &:focus,
        &:active {
          color: $Orange;
          transition: 200ms;
          transition-timing-function: ease-in-out;
        }
      }

      &:hover,
      &:focus,
      &:active {
        color: $Orange;
        transition: 200ms;
        transition-timing-function: ease-in-out;
      }
    }
  }

  input[type="checkbox"] {
    margin-right: 15px;
  }
}

.btn {
  display: flex;
  width: 280px;
  height: 60px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border: none;
  cursor: pointer;
  color: $White;

  &:disabled {
    opacity: 0.5;
  }

  &__orange {
    background-color: $Orange;
    transition: 200ms;
    transition-timing-function: ease-in-out;

    &:hover,
    &:focus,
    &:active {
      background-color: $SeaBlue;
      transition: 200ms;
      transition-timing-function: ease-in-out;

      &:disabled {
        color: $White;
        background-color: $Orange;
        opacity: 0.5;
      }
    }
  }

  &__seablue {
    border: 2px solid $Orange;
    transition: 200ms;
    transition-timing-function: ease-in-out;

    &:hover,
    &:focus {
      border-color: #1b1c2e;
      transition: 200ms;
      transition-timing-function: ease-in-out;
    }

    &:active {
      background-color: #1b1c2e;
      transition: 200ms;
      transition-timing-function: ease-in-out;
    }
  }
}
