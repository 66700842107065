.contacts {
  &__wrapper {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  &__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    &__title {
      align-self: flex-start;
      margin-top: 40px;
      margin-bottom: 13px;
      font-weight: bold;
      line-height: 29px;
    }
    &__descr {
      align-self: flex-start;
      font-weight: 300;
      line-height: 29px;
      margin-bottom: 13px;
      &__bold {
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .contacts {
    &__wrapper {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__title {
      font-weight: bold;
      font-size: 22px;
      line-height: 130%;
      letter-spacing: 0.01em;
      text-transform: uppercase;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      flex-wrap: wrap;
      img {
        max-width: 150px;
      }
      &__title {
        align-self: flex-start;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        line-height: 22px;
        font-size: 18px;
      }
      &__descr {
        align-self: flex-start;
        font-weight: 300;
        line-height: 20px;
        font-size: 16px;
        margin-bottom: 10px;
        &__bold {
          font-weight: bold;
        }
      }
    }
  }
}
