.popular {
  background: linear-gradient(to right, $Grey 50%, $Orange 50%);
  padding-top: 120px;
  padding-bottom: 110px;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__column-right {
    padding-left: 87px;
    .popular__item {
      margin-bottom: 40px;
      color: $White;
      &__bold {
        font-weight: bold;
      }
    }
    .popular__title {
      margin-left: -20px;
    }
  }

  &__title {
    max-width: 460px;
    margin-bottom: 61px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    text-transform: uppercase;
  }

  &__item {
    position: relative;
    padding-left: 31px;
    margin-bottom: 29px;
    font-size: 24px;
    line-height: 29px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item-marker {
    position: absolute;
    top: 5px;
    left: -17px;
    width: 27px;
    height: 19px;
  }
}

@media screen and (max-width: 1200px) {
  .popular {
    padding-top: 50px;
    padding-bottom: 50px;

    &__title {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 780px) {
  .popular {
    background: linear-gradient($Grey 60%, $Orange 40%);
    &__wrapper {
      grid-template-columns: 1fr;
      grid-template-rows: 60% 40%;
    }
    &__title {
      font-size: 20px;
      line-height: 100%;
    }

    &__column-right {
      padding-top: 50px;
      padding-left: 0;
      .popular__item {
        margin-bottom: 20px;
      }
    }

    &__item {
      font-size: 16px;
      line-height: 21px;
    }
  }
}
