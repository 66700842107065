.works {
  background-color: $White;
  background-image: url(/img/works.png);
  background-size: 875px cover;
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  padding-top: 140px;
  padding-bottom: 140px;

  &__title-wrapper {
    margin-top: 255px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }

  &__title {
    max-width: 550px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    &__numb {
      font-size: 64px;
    }
    &__orange {
      color: $Orange;
    }
  }

  &__subtitle {
    width: 161px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: $SeaBlue;
  }

  &__subtitle-wrapper {
    width: 580px;
    height: 118px;
    margin-left: -52px;
    border: 2px solid #ec6519;
    box-sizing: border-box;
    border-radius: 60px;
    padding-left: 54px;
    display: flex;
    align-items: center;
  }

  &__play {
    width: 170px;
    height: 170px;
    vertical-align: middle;
    &__link {
      margin-left: 80px;
      &:focus,
      :hover {
        fill: $Orange;
      }
    }
  }

  &__text-wrapper {
    max-width: 607px;
    margin-bottom: 113px;
    border: 2px solid $Orange;
    padding-top: 30px;
    padding-left: 41px;
    padding-bottom: 28px;
  }

  &__text-title {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
  }

  &__text-item {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    &__nobold {
      margin-left: 35px;
      font-weight: normal;
    }

    &::before {
      content: "";
      background: $SeaBlue;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      text-align: center;
      vertical-align: middle;
      margin-right: 22px;
      background: $Orange;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    margin-bottom: 63px;
  }

  &__item {
    position: relative;
    max-width: 550px;
    padding-top: 38px;
    padding-right: 30px;
    transition: 200ms;
    transition-timing-function: ease-in-out;
    border-bottom: 1px solid $Grey;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      border-bottom: 1px solid $Orange;
      transition: 200ms;
      transition-timing-function: ease-in-out;

      .marker__plus {
        &:before,
        &:after {
          transition: 200ms;
          transition-timing-function: ease-in-out;
          background-color: $Orange;
        }
      }
    }
  }

  &__item-title {
    margin-bottom: 26px;
    font-weight: 300;
    line-height: 29px;
  }

  &__item-text {
    display: none;

    &__active {
      display: block;
      font-size: 18px;
      line-height: 22px;
      color: $DarkGrey;
      margin-bottom: 30px;
    }
  }

  &__label {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }

  &__label-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__label-text {
    max-width: 400px;
    margin-left: 31px;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $Orange;
  }
}

@media screen and (max-width: 1200px) {
  .works {
    padding-top: 57px;
    padding-bottom: 0px;

    &__text-wrapper {
      margin-bottom: 53px;
    }

    &__subtitle {
      width: 180px;
    }

    &__subtitle-wrapper {
      margin-bottom: 25px;
      width: 380px;
      margin-left: 0;
      padding-left: 40px;
    }
    &__play {
      &__link {
        margin-left: 40px;
      }
    }

    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 15px;
      font-size: 20px;
      line-height: 26px;
    }

    &__label {
      display: none;
    }
  }
}

@media screen and (max-width: 780px) {
  .works {
    background-size: 100%;
    &__title {
      font-size: 22px;
      line-height: 130%;
    }

    &__subtitle {
      width: 200px;
      font-size: 14px;
      line-height: 18px;
    }

    &__subtitle-wrapper {
      margin-bottom: 20px;
      width: 100%;
      height: 80px;
      margin-left: 0;
      border-radius: 60px;
      padding-left: 30px;
    }

    &__play {
      width: 70px;
      height: 70px;
      vertical-align: middle;
      &__link {
        margin-left: 0;
        margin-right: 5px;
      }
    }

    &__title-wrapper {
      margin-top: 50px;
      display: block;
    }

    &__text-wrapper {
      max-width: 607px;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
    }

    &__title {
      max-width: 100%;
      font-size: 18px;
      line-height: 100%;
      &__numb {
        font-size: 24px;
      }
      &__orange {
        color: $Orange;
      }
    }

    &__text-title {
      font-size: 18px;
      line-height: 22px;
    }

    &__text-item {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
