.consultation {
  background: linear-gradient(180deg, #96d5e7 0%, #489bba 100%);

  &__bg {
    background-image: url(../img/consultation.png);
    background-position: bottom -40px right -80px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 89px;
    padding-bottom: 160px;
    padding-right: 280px;
  }

  &__title-wrapper {
    max-width: 540px;
  }

  &__title {
    max-width: 473px;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: 1%;
    margin-bottom: 60px;

    &__bold {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  &__item {
    max-width: 452px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;

    &::before {
      content: "";
      background: $Orange;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      text-align: center;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .consultation {
    &__bg {
      background-position: bottom -40px right -120px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .consultation {
    &__bg {
      background-image: none;
    }
    &__wrapper {
      justify-content: flex-start;
      padding-top: 57px;
      padding-bottom: 50px;
      padding-left: 40px;
    }

    &__title {
      max-width: 700px;
      margin-bottom: 30px;
    }

    &__form {
      margin-right: auto;
    }
  }
}

@media screen and (max-width: 780px) {
  .consultation {
    &__title {
      font-size: 22px;
      line-height: 130%;
    }

    &__form {
      max-width: 100%;
      margin-right: auto;
    }

    &__item {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
}
