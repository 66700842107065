.popup {
    z-index: 1000;
    position: fixed;
    overflow-y: scroll;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $Grey;

    &__wrapper {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) !important;
        top: 5%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        background: $White
    }

    &__close {
        position: absolute;
        top: 13px;
        right: 13px;
        width: 27px;
        height: 21px;
        background: transparent;
        color: $DarkGrey;
        border: none;
        outline: none;
    }

    &__title {
        text-align: center;
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 130%;
        font-weight: 700;
    }

    &__text {
        text-align: center;
        font-size: 22px;
        line-height: 100%;
        margin-bottom: 15px;
    }

    &__embedded-subscribe {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    &__btn {
        margin: 0 auto;
    }
}